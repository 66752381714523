import React, {useEffect, useState, useContext} from 'react'

import {connect} from 'react-redux'
import {withRouter} from 'react-router'

import TopLoader from '../loaders/TopLoader'
import SideMenu from '../side-menu/SideMenu'
import MenuButton from './MenuButton'
import Navigation from './Navigation'
import MainIcon from './MainIcon'
import textsData from '../../staticTexts'

import {actions} from '../../redux/actions/auth'
import {languageContext} from '../../languageContext'

import {
  navigationArray,
  menuArray,
  languagesArray,
} from '../../utils/utilityArrays'

import ClipLoader from 'react-spinners/ClipLoader'
const Main = (props) => {
  //* Getting the selected language by the user
  const {value} = useContext(languageContext)
  const [sidebarState, setSidebarState] = useState(false)
  const [activeLink, setActiveLink] = useState('')
  //* Calling the specific text from the static text file
  const [texts] = useState(textsData[14])

  useEffect(() => {
    window.addEventListener('online', () => {
      handleConnectionChange()
    })
    window.addEventListener('offline', () => {
      handleConnectionChange()
    })
    return () => {
      window.removeEventListener('online', () => {
        handleConnectionChange()
      })
      window.removeEventListener('offline', () => {
        handleConnectionChange()
      })
    }
  }, [])

  const changeSidebarState = (status) => {
    setSidebarState(status)
  }

  const changeActiveLink = (link) => {
    setActiveLink(link)
  }

  //* Check if the user has connection or not
  const handleConnectionChange = () => {
    const condition = navigator.onLine ? 'online' : 'offline'
    if (condition === 'online') {
      const webPing = setInterval(() => {
        fetch('//google.com', {
          mode: 'no-cors',
        })
          .then(() => {
            props.handleConnection(false)
            clearInterval(webPing)
          })
          .catch(() => {})
      }, 10000)
      return
    }
    props.handleConnection(true)
  }

  return (
    <div style={{position: 'fixed', top: 0, left: 0, right: 0, zIndex: 9999}}>
      <div
        className="flex items-center justify-between px-4 sm:px-6 lg:px-8 bg-ecco2blue-100 overflow-hidden"
        style={{height: '67px'}}
      >
        <MainIcon link={props.prevPage} />

        <div className="grid place-items-center">
          <div className="flex items-center space-x-2">
            {navigationArray.map((nav, i) => (
              <Navigation
                key={i}
                active={activeLink}
                title={nav.title}
                text={texts[value][`${nav.index}`]}
                change={changeActiveLink}
              />
            ))}
            {props.emrSettings?.loading === true ||
              (props.loadingData === 2 && (
                <ClipLoader
                  className="ml-4"
                  loading={true}
                  color={'#fff'}
                  size={25}
                />
              ))}
          </div>
        </div>
        <MenuButton change={changeSidebarState} sidebarStatus={sidebarState} />
      </div>

      {props.loadingValue && (
        <TopLoader
          size={25}
          text={texts[value].loadingMsg}
          loading={props.loadingValue}
        />
      )}
      {sidebarState && (
        <SideMenu
          sidebarStatus={sidebarState}
          active={activeLink}
          change={changeSidebarState}
          texts={texts[value]}
          navArray={menuArray}
          langArray={languagesArray}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  loadingData: state.global.loadingData,
  prevPage: state.global.prevPage,
  entity: state.global.entity,
  subEntity: state.global.subEntity,
  emrSettings: state.global.emrSettings,
})

const mapDispatchToProps = (dispatch) => ({
  handleConnection: (data) => dispatch(actions.handleConnection(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Main))
