import {
  PROJECT_SET,
  PROJECT_RESET,
  MAIN_ENTITIES_SET,
  MAIN_ENTITIES_RESET,
  // LOADING_SET,
  // LOADING_RESET,
  INDEX_SET,
  INDEX_RESET,
  DATA_ERROR_SET,
  DATA_ERROR_RESET,
} from '../actions/project'

const initialState = {
  allEntities: {},
  selectedProject: {},
  selectedIndex: {},
  // loadingData: 999,
  dataError: [],
}
function project(state = initialState, action) {
  switch (action.type) {
    case MAIN_ENTITIES_SET:
      return Object.assign({}, state, {
        allEntities: action.payload.allEntities,
      })
    case MAIN_ENTITIES_RESET:
      return Object.assign({}, state, {
        allEntities: {},
      })
    case PROJECT_SET:
      return Object.assign({}, state, {
        selectedProject: action.payload.selectedProject,
      })
    case PROJECT_RESET:
      return Object.assign({}, state, {
        selectedProject: {},
      })
    case INDEX_SET:
      return Object.assign({}, state, {
        selectedIndex: action.payload.selectedIndex,
      })
    case INDEX_RESET:
      return Object.assign({}, state, {
        selectedIndex: {},
      })
    // case LOADING_SET:
    //   return Object.assign({}, state, {
    //     loadingData: action.payload.loadingData,
    //   });
    // case LOADING_RESET:
    //   return Object.assign({}, state, {
    //     loadingData: 999,
    //   });
    case DATA_ERROR_SET:
      return Object.assign({}, state, {
        dataError: action.payload.dataError,
      })
    case DATA_ERROR_RESET:
      return Object.assign({}, state, {
        dataError: [],
      })
    default:
      return state
  }
}

export default project
