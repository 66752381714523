import {
  RESET,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  CONNECTION_CHANGE,
  FETCHING_ERROR,
} from '../actions/auth'

const initialState = {
  loading: false,
  message: '',
  token: null,
  userData: {},
  userName: '',
  errorMessage: '',
  isDisconnected: false,
  popupState: false,
}

function auth(state = initialState, action) {
  switch (action.type) {
    case CONNECTION_CHANGE:
      return Object.assign({}, state, {
        isDisconnected: action.payload.connection,
      })

    case RESET:
      return Object.assign({}, state, {
        loading: false,
        loginError: null,
        message: '',
        token: null,
        userData: {},
        userName: '',
        forgotMessage: '',
        errorMessage: '',
      })

    case LOGIN_REQUEST:
      return Object.assign({}, state, {
        ...state,
        loading: true,
        message: '',
        token: null,
      })
    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        token: action.payload.token,
        message: '',
        loading: false,
        userData: action.payload.user,
      })
    case LOGIN_ERROR:
      return Object.assign({}, state, {
        ...state,
        token: null,
        userData: null,
        loading: false,
        message: action.payload.message,
      })
    case LOGOUT_REQUEST:
      return Object.assign({}, state, {
        message: '',
        token: null,
        userData: {},
        userName: '',
      })
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        token: null,
        message: '',
      })
    case LOGOUT_ERROR:
      return Object.assign({}, state, {
        loading: false,
        loadingValue: false,
        token: null,
        message: action.payload.message,
      })

    case FETCHING_ERROR:
      return Object.assign({}, state, {
        popupState: action.payload,
      })

    default:
      return state
  }
}

export default auth
