import {getBuildingsList} from '../../api/configuration'
import {authenticate} from '../../api/accessRights'
import {globalActions} from './global'
export const RESET = 'RESET'

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_ERROR = 'LOGOUT_ERROR'

export const CONNECTION_CHANGE = 'CONNECTION_CHANGE'

export const FETCHING_ERROR = 'FETCHING_ERROR'

const {
  MAIN_ENTITIES_RESET,
  MAIN_ENTITIES_SET,
  LOADING_SET,
  LOADING_RESET,
  DATA_ERROR_SET,

  MAIN_MEASUREMENTS_SET,
  MAIN_MEASUREMENTS_RESET,
  INDEX_RESET,

  EMR_RESET,

  SUB_IDENTIFIER_RESET,
  APARTMENT_MEASURES_RESET,
} = require('./global')

const {
  getAvgWeekHistory,
  getRoomWeekHistory,
  getLogs,
  getEmrHistory,
} = require('../../api/configuration')

export const resetReducer = () => {
  return {
    type: RESET,
  }
}

export const fetchData = {
  fetch: (user, configurations, history) => async (dispatch, getState) => {
    try {
      dispatch({
        type: LOADING_SET,
        payload: {
          loadingData: 2,
        },
      })

      const avgHistory = await getAvgWeekHistory(
        configurations[1],
        user,
        getState().global.allEntities,
      )

      if (avgHistory.error) {
        if (avgHistory.serverError) {
          dispatch({
            type: DATA_ERROR_SET,
            payload: {
              dataError: [{avgHistory: `${avgHistory.code}`}],
            },
          })
        } else if (avgHistory.refreshError || avgHistory.connError) {
          dispatch({
            type: LOGIN_ERROR,
            payload: {
              message: avgHistory.refreshError
                ? '909'
                : avgHistory.connError
                ? '090'
                : '000',
            },
          })
        }
        dispatch({
          type: FETCHING_ERROR,
          payload: true,
        })
      } else {
        dispatch({
          type: MAIN_ENTITIES_SET,
          payload: {
            allEntities: avgHistory,
          },
        })
      }

      dispatch({
        type: LOADING_SET,
        payload: {
          loadingData: 3,
        },
      })

      const relHistory = await getRoomWeekHistory(
        configurations[2],
        user,
        getState().global.allEntities,
      )

      if (relHistory.error) {
        if (relHistory.serverError) {
          dispatch({
            type: DATA_ERROR_SET,
            payload: {
              dataError: [
                ...getState().global.dataError,
                {relHistError: `${relHistory.code}`},
              ],
            },
          })
        } else if (relHistory.refreshError || relHistory.connError) {
          dispatch({
            type: LOGIN_ERROR,
            payload: {
              message: relHistory.refreshError
                ? '909'
                : relHistory.connError
                ? '090'
                : '000',
            },
          })
        }
        dispatch({
          type: FETCHING_ERROR,
          payload: true,
        })
      } else {
        dispatch({
          type: MAIN_ENTITIES_SET,
          payload: {
            allEntities: relHistory,
          },
        })
      }

      dispatch({
        type: LOADING_SET,
        payload: {
          loadingData: 4,
        },
      })

      const emrHistory = await getEmrHistory(
        configurations[4],
        user,
        getState().global.allEntities,
      )

      if (emrHistory?.error) {
        if (emrHistory.serverError) {
          dispatch({
            type: DATA_ERROR_SET,
            payload: {
              dataError: [
                ...getState().global.dataError,
                {emrHistError: `${emrHistory.code}`},
              ],
            },
          })
        } else if (emrHistory.refreshError || emrHistory.connError) {
          dispatch({
            type: LOGIN_ERROR,
            payload: {
              message: emrHistory.refreshError
                ? '909'
                : emrHistory.connError
                ? '090'
                : '000',
            },
          })
        }
        dispatch({
          type: FETCHING_ERROR,
          payload: true,
        })
      } else {
        dispatch({
          type: MAIN_ENTITIES_SET,
          payload: {
            allEntities: emrHistory,
          },
        })
      }

      dispatch({
        type: LOADING_SET,
        payload: {
          loadingData: 5,
        },
      })

      const logbookValues = await getLogs(
        configurations[3],
        user,
        getState().global.allEntities,
      )

      if (logbookValues.error) {
        if (logbookValues.serverError) {
          dispatch({
            type: DATA_ERROR_SET,
            payload: {
              dataError: [
                ...getState().global.dataError,
                {logError: `${logbookValues.code}`},
              ],
            },
          })
        } else if (logbookValues.refreshError || logbookValues.connError) {
          dispatch({
            type: LOGIN_ERROR,
            payload: {
              message: logbookValues.refreshError
                ? '909'
                : logbookValues.connError
                ? '090'
                : '000',
            },
          })
        }
        dispatch({
          type: FETCHING_ERROR,
          payload: true,
        })
      } else {
        dispatch({
          type: MAIN_ENTITIES_SET,
          payload: {
            allEntities: logbookValues,
          },
        })
        dispatch({
          type: LOADING_SET,
          payload: {
            loadingData: 6,
          },
        })
      }

      dispatch({
        type: LOADING_RESET,
      })
      const now = new Date()
      const item = {
        value: 'dataLoaded',
        expiry: now.getTime() + 3600000,
      }
      localStorage.setItem('API_REMINDER', JSON.stringify(item))
    } catch (error) {
      dispatch({
        type: LOADING_RESET,
      })
      history.push('/login')

      dispatch({
        type: LOGIN_ERROR,
        payload: {
          message: error.code ? `${error.code}` : '000',
        },
      })
    }
  },
}

export const actions = {
  login: (data, history) => async (dispatch) => {
    try {
      dispatch({
        type: LOGIN_REQUEST,
      })

      dispatch({
        type: LOADING_SET,
        payload: {
          loadingData: 1,
        },
      })

      const loginResponse = await authenticate(data)
      if (loginResponse.error) {
        dispatch({
          type: LOADING_RESET,
        })
        history.push('/login')

        dispatch({
          type: LOGIN_ERROR,
          payload: {
            message:
              loginResponse.code === 402
                ? '402'
                : loginResponse.serverError
                ? `${loginResponse.code}`
                : loginResponse.refreshError
                ? '909'
                : loginResponse.connError
                ? '090'
                : '000',
          },
        })
        return
      }

      const response = await getBuildingsList(data)

      if (response.error) {
        dispatch({
          type: LOADING_RESET,
        })
        history.push('/login')

        dispatch({
          type: LOGIN_ERROR,
          payload: {
            message: response.serverError
              ? `${response.code}`
              : response.refreshError
              ? '909'
              : response.connError
              ? '090'
              : '000',
          },
        })
        return
      } else {
        await dispatch({
          type: MAIN_ENTITIES_SET,
          payload: {
            allEntities: response.mainData,
            emrIdentifiers: response.emrIdentifiers,
          },
        })

        await dispatch({
          type: MAIN_MEASUREMENTS_SET,
          payload: response.buildingsDailyMeasures,
        })

        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            user: data,
            token: 'ABCDEFGHIJKLMNOP',
          },
        })

        dispatch({
          type: LOADING_SET,
          payload: {
            loadingData: 3,
          },
        })

        history.push('/home')

        // fetch emr details
        dispatch(globalActions.getEmrDetails())
      }
    } catch (error) {
      dispatch({
        type: LOADING_RESET,
      })
      history.push('/login')

      dispatch({
        type: LOGIN_ERROR,
        payload: {
          message: '100',
        },
      })
    }
  },

  loginError: (data) => async (dispatch) => {
    dispatch({
      type: LOGIN_ERROR,
      payload: {
        message: data.code ? `${data.code}` : '000',
      },
    })
  },

  logOut: () => async (dispatch) => {
    try {
      dispatch({
        type: LOGOUT_REQUEST,
      })

      dispatch({
        type: MAIN_ENTITIES_RESET,
      })
      dispatch({
        type: MAIN_MEASUREMENTS_RESET,
      })
      dispatch({
        type: EMR_RESET,
      })

      dispatch({type: INDEX_RESET})

      dispatch({
        type: SUB_IDENTIFIER_RESET,
      })

      dispatch({
        type: APARTMENT_MEASURES_RESET,
      })

      localStorage.removeItem('API_REMINDER')
      localStorage.removeItem('APP_VERSION')

      dispatch({
        type: LOADING_RESET,
      })
    } catch (error) {
      dispatch({
        type: LOGOUT_ERROR,
        payload: {
          message: 'Something went wrong, please try again.',
        },
      })
    }
  },

  handleConnection: (data) => async (dispatch) => {
    dispatch({
      type: CONNECTION_CHANGE,
      payload: {
        connection: data,
      },
    })
  },
}

export const fetchingError = {
  fetchError: (data) => async (dispatch) => {
    dispatch({
      type: FETCHING_ERROR,
      payload: data,
    })
  },
}
