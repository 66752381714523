import fetch from 'isomorphic-fetch'

let {BASE_URL} = require('./config')

// Api used to add log
export const postLog = async (user, logEntry, reEntry = false) => {
  try {
    let logbookInsert = await fetch(`${BASE_URL}logbook/insert`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Basic ${window.btoa(
          `${user.username}:${user.password}`,
        )}`,
      },
      body: JSON.stringify({
        request: {logEntry},
      }),
    })

    if (window.location.search === '?showlog=true') {
      console.log('add log response', logbookInsert)
    }
    if (!logbookInsert.ok) {
      let errorResData = await logbookInsert.json()
      let error = {
        message: errorResData.message,
      }

      throw error
    }
    let logbookResInsert = await logbookInsert.json()

    return logbookResInsert
  } catch (error) {
    if (!reEntry) {
      const now = new Date()
      localStorage.setItem(`log.${now.getTime()}`, JSON.stringify(logEntry))
    }
    return 'error'
  }
}
