import {createContext} from 'react'

const detectLanguage = () => {
  let language = navigator.language || navigator.userLanguage
  let lang = 1
  if (language.startsWith('cs')) {
    lang = 4
  } else if (language.startsWith('fr')) {
    lang = 2
  } else if (language.startsWith('en')) {
    lang = 1
  } else if (language.startsWith('de')) {
    lang = 3
  }
  return lang
}

const lang = detectLanguage()

//* Creating a context for app language
export const languageContext = createContext(lang)
