import {
  getEmrHistory,
  getBuildingAppartments,
  getLogbook,
  getMesauresHistory,
  getBuilding24hMeasures,
  getHeatingGroups,
  getApartmentMeasure,
  getGummiband,
  getBuildingsList,
} from '../../api/configuration'

import {getAccessRights, authenticate} from '../../api/accessRights'
import {internetAccess} from '../../api/connection'

export const RESET_BUILDING_AVG_MEASURES_RETRY =
  'RESET_BUILDING_AVG_MEASURES_RETRY'
export const RESET_BUILDING_LOADING_RESET = 'RESET_BUILDING_LOADING_RESET'
export const RELOAD_DATA = 'RELOAD_DATA'
export const MAIN_ENTITIES_SET = 'MAIN_ENTITIES_SET'
export const MAIN_ENTITIES_RESET = 'MAIN_ENTITIES_RESET'
export const ENTITY_SET = 'ENTITY_SET'
export const ENTITY_RESET = 'ENTITY_RESET'
export const LOADING_SET = 'LOADING_SET'
export const LOADING_RESET = 'LOADING_RESET'
export const DATA_ERROR_SET = 'DATA_ERROR_SET'
export const DATA_ERROR_RESET = 'DATA_ERROR_RESET'
export const SUB_ENTITY_SET = 'SUB_ENTITY_SET'
export const SUB_ENTITY_RESET = 'SUB_ENTITY_RESET'
export const MENU_STATE_SET_BLDG = 'MENU_STATE_SET_BLDG'
export const MENU_STATE_SET_PROJ = 'MENU_STATE_SET_PROJ'
export const MENU_STATE_RESET = 'MENU_STATE_RESET'
export const INDEX_SET = 'INDEX_SET'
export const INDEX_RESET = 'INDEX_RESET'

export const SUBINDEX_SET = 'SUBINDEX_SET'
export const SUBINDEX_RESET = 'SUBINDEX_RESET'

export const PREV_PAGE_SET = 'PREV_PAGE_SET'
export const PREV_PAGE_RESET = 'PREV_PAGE_RESET'

export const EMR_SET = 'EMR_SET'
export const EMR_RESET = 'EMR_RESET'

export const MAIN_ENTITES_RESHAPE = 'MAIN_ENTITES_RESHAPE'

export const MAIN_MEASUREMENTS_SET = 'MAIN_MEASUREMENTS_SET'
export const MAIN_MEASUREMENTS_RESET = 'MAIN_MEASUREMENTS_RESET'
export const MAIN_MEASUREMENTS_RESHAPE = 'MAIN_MEASUREMENTS_RESHAPE'

export const HEATINGGROUPS_RESHAPE = 'HEATINGGROUPS_RESHAPE'

export const APARTMENTS_7D_RESHAPE = 'APARTMENTS_7D_RESHAPE'

export const BUILDING_LOG_RESHAPE = 'BUILDING_LOG_RESHAPE'

export const BUILDING_7D_RESHAPE = 'BUILDING_7D_RESHAPE'

export const TEMPORARY_OFFSET_RESHAPE = 'TEMPORARY_OFFSET_RESHAPE'

export const APARTMENT_MEASURES_SET = 'APARTMENT_MEASURES_SET'
export const APARTMENT_MEASURES_RESET = 'APARTMENT_MEASURES_RESET'

export const SUB_IDENTIFIER_SET = 'SUB_IDENTIFIER_SET'
export const SUB_IDENTIFIER_RESET = 'SUB_IDENTIFIER_RESET'

export const EMR_DETAILS_SET = 'EMR_DETAILS_SET'

export const APARTMENT_FROM_SEARCH = 'APARTMENT_FROM_SEARCH'

export const AVG_MEASURES_RESETLOADING = 'AVG_MEASURES_RESETLOADING'

export const SET_LANGUAGE = 'SET_LANGUAGE'

export const ACCESS_RIGHTS_SET = 'ACCESS_RIGHTS_SET'
export const globalActions = {
  setLanguage: (language) => (dispatch) => {
    dispatch({
      type: SET_LANGUAGE,
      payload: language,
    })
  },

  getEmrDetails: () => async (dispatch, getState) => {
    let emrSettings = getState()?.global?.emrSettings
    const userData = getState()?.auth?.userData
    const allEntities = getState()?.global?.allEntities

    try {
      const currentTime = new Date()
      if (
        emrSettings?.expiery === '' ||
        currentTime.getTime() >= emrSettings?.expiery
      ) {
        dispatch({
          type: EMR_SET,
          payload: {
            loading: true,
            expiery: emrSettings?.expiery,
          },
        })
        const response = await getEmrHistory(
          getState()?.global?.emrIdentifiers,
          userData,
          allEntities,
        )
        if (!response.error) {
          // dispatch({ type: MAIN_ENTITES_RESHAPE, payload: response })
          const now = new Date()
          dispatch({
            type: EMR_SET,
            payload: {
              loading: false,
              expiery: now.getTime() + 3600000,
            },
          })

          dispatch({
            type: EMR_DETAILS_SET,
            payload: response,
          })
        } else {
          dispatch({
            type: EMR_SET,
            payload: {
              loading: false,
              expiery: emrSettings?.expiery,
            },
          })
        }
      }
    } catch (err) {
      dispatch({
        type: EMR_SET,
        payload: {
          loading: false,
          expiery: emrSettings?.expiery,
        },
      })
    }
  },

  getBuildingAppartments: (data) => async (dispatch, getState) => {
    const userData = getState()?.auth?.userData
    const allEntities = getState()?.global?.allEntities
    const selectedIndex = getState()?.global?.selectedEntityIndex
    const apartmentsSettings = allEntities[selectedIndex]?.apartmentsSettings
    const now = new Date()

    if (
      (apartmentsSettings?.expiery === '' ||
        now?.getTime() >= apartmentsSettings?.expiery) &&
      apartmentsSettings?.loading === false
    ) {
      dispatch({
        type: APARTMENTS_7D_RESHAPE,
        payload: {
          data: {settings: {loading: true, expiery: ''}},
          index: selectedIndex,
        },
      })

      const response = await getBuildingAppartments(
        [data.buildingIds],
        userData,
        allEntities,
        selectedIndex,
      )
      if (!response.error) {
        const now = new Date()
        dispatch({
          type: APARTMENTS_7D_RESHAPE,
          payload: {
            data:
              response.length > 0
                ? {
                    settings: {
                      loading: false,
                      expiery: now.getTime() + 3600000,
                    },
                    data: response,
                  }
                : {
                    settings: {
                      loading: false,
                      expiery: now.getTime() + 3600000,
                    },
                  },
            index: selectedIndex,
          },
        })
      } else {
        dispatch({
          type: APARTMENTS_7D_RESHAPE,
          payload: {
            data: {settings: {loading: false, expiery: ''}},
            index: selectedIndex,
          },
        })
      }
    }
  },

  getBuildingLogs: (data) => async (dispatch, getState) => {
    const userData = getState()?.auth?.userData
    const allEntities = getState()?.global?.allEntities
    const selectedIndex = getState()?.global?.selectedEntityIndex
    const logsSettings = allEntities[selectedIndex]?.logsSettings

    if (
      // (
      logsSettings?.expiery === '' &&
      // ||
      // now?.getTime() >= logsSettings?.expiery)
      logsSettings?.loading === false
    ) {
      // SETTING LOADING TRUE
      dispatch({
        type: BUILDING_LOG_RESHAPE,
        payload: {
          data: {settings: {loading: true, expiery: ''}},
          index: selectedIndex,
        },
      })
      const response = await getLogbook(data.projectId, userData)
      if (!response.error) {
        dispatch({
          type: BUILDING_LOG_RESHAPE,
          payload: {
            data:
              response.length > 0
                ? {
                    settings: {
                      loading: false,
                      expiery: '',
                      // now.getTime() + 3600000,
                    },
                    data: response,
                  }
                : {
                    settings: {
                      loading: false,
                      expiery: '',
                      // now.getTime() + 3600000,
                    },
                  },
            index: selectedIndex,
          },
        })
      } else {
        //  IF ERROR IN REQUEST RESET LOADING TO FALSE AND EXPIERY TO NULL
        dispatch({
          type: BUILDING_LOG_RESHAPE,
          payload: {
            data: {settings: {loading: false, expiery: ''}},
            index: selectedIndex,
          },
        })
      }
    }
  },

  getBuilding7dMeasures: () => async (dispatch, getState) => {
    const userData = getState()?.auth?.userData
    const allEntities = getState()?.global?.allEntities
    const selectedIndex = getState()?.global?.selectedEntityIndex
    const historyMeasuresSettings =
      allEntities[selectedIndex]?.historyMeasuresSettings

    const now = new Date()

    if (
      (historyMeasuresSettings?.expiery === '' ||
        now?.getTime() >= historyMeasuresSettings?.expiery) &&
      historyMeasuresSettings?.loading === false
    ) {
      dispatch({
        type: BUILDING_7D_RESHAPE,
        payload: {
          data: {settings: {loading: true, expiery: ''}},
          index: selectedIndex,
        },
      })
      const response = await getMesauresHistory(
        allEntities[selectedIndex]?.buildingIdentifier,
        userData,
      )
      if (!response.error) {
        //  SETTING RESPONSE
        const now = new Date()
        dispatch({
          type: BUILDING_7D_RESHAPE,
          payload: {
            data:
              response.length > 0
                ? {
                    settings: {
                      loading: false,
                      expiery: now.getTime() + 3600000,
                    },
                    data: response,
                  }
                : {
                    settings: {
                      loading: false,
                      expiery: now.getTime() + 3600000,
                    },
                  },
            index: selectedIndex,
          },
        })
      } else {
        //  IF ERROR IN REQUEST RESET LOADING TO FALSE AND EXPIERY TO NULL
        dispatch({
          type: BUILDING_7D_RESHAPE,
          payload: {
            data: {settings: {loading: false, expiery: ''}},
            index: selectedIndex,
          },
        })
      }
    }
  },

  getBuilding24hMeasures:
    (buildingIdentifiers, index) => async (dispatch, getState) => {
      const userData = getState()?.auth?.userData
      const beforeLoadlatestMeasurements =
        getState()?.global?.buildingsAvgMeasures
      dispatch({
        type: MAIN_MEASUREMENTS_RESHAPE,
        payload: {
          data: {
            settings: {
              loading: true,
              expiery: '',
              retry: beforeLoadlatestMeasurements[index]?.settings?.retry || 0,
            },
          },
          index: index,
        },
      })

      let response = await getBuilding24hMeasures(
        buildingIdentifiers,
        userData,
        index,
      )
      if (!response.error) {
        //  SETTING RESPONSE
        const now = new Date()
        dispatch({
          type: MAIN_MEASUREMENTS_RESHAPE,
          payload: {
            data:
              response.length > 0
                ? {
                    settings: {
                      loading: false,
                      expiery: now.getTime() + 3600000,
                      retry: 0,
                    },
                    data: response,
                  }
                : {
                    settings: {
                      loading: false,
                      expiery: now.getTime() + 3600000,
                      retry: 0,
                    },
                  },
            index: index,
          },
        })
      } else {
        const latestMeasurements = getState()?.global?.buildingsAvgMeasures
        //  IF ERROR IN REQUEST RESET LOADING TO FALSE AND EXPIERY TO NULL
        let LoadingFalseReshape = []
        let retry = 0
        for (let i = 0; i < latestMeasurements?.length; i++) {
          if (i === index) {
            retry = latestMeasurements[i]?.settings?.retry || 0
            LoadingFalseReshape.push({...latestMeasurements[i]})
          } else {
            LoadingFalseReshape.push(latestMeasurements[i])
          }
        }
        dispatch({
          type: MAIN_MEASUREMENTS_RESHAPE,
          payload: {
            data: {
              settings: {loading: false, expiery: '', retry: retry + 1},
              data: LoadingFalseReshape,
            },
            index: index,
          },
        })
      }
    },

  resetRetriesBuilding24hMeasures: () => async (dispatch) => {
    dispatch({
      type: RESET_BUILDING_AVG_MEASURES_RETRY,
    })
  },

  resetLoadingOfBuilding: () => async (dispatch) => {
    dispatch({
      type: RESET_BUILDING_LOADING_RESET,
    })
  },

  getHeatingGroups: () => async (dispatch, getState) => {
    const userData = getState()?.auth?.userData
    const allEntities = getState()?.global?.allEntities
    const selectedIndex = getState()?.global?.selectedEntityIndex
    const heatingGroupsSettings =
      allEntities[selectedIndex]?.heatingGroupsSettings

    const now = new Date()
    if (
      (heatingGroupsSettings?.expiery === '' ||
        now?.getTime() >= heatingGroupsSettings?.expiery) &&
      heatingGroupsSettings?.loading === false
    ) {
      dispatch({
        type: HEATINGGROUPS_RESHAPE,
        payload: {
          data: {settings: {loading: true, expiery: ''}},
          index: selectedIndex,
        },
      })
      const response = await getHeatingGroups(
        [allEntities[selectedIndex]?.buildingIdentifier],
        userData,
      )
      if (!response.error) {
        const now = new Date()
        if (response?.length > 0) {
          dispatch({
            type: HEATINGGROUPS_RESHAPE,
            payload: {
              data: {
                settings: {loading: false, expiery: now.getTime() + 3600000},
                heatingGroups: response[0] || {},
              },
              index: selectedIndex,
            },
          })
        } else {
          dispatch({
            type: HEATINGGROUPS_RESHAPE,
            payload: {
              data: {
                settings: {loading: false, expiery: now.getTime() + 3600000},
              },
              index: selectedIndex,
            },
          })
        }
      } else {
        dispatch({
          type: HEATINGGROUPS_RESHAPE,
          payload: {
            data: {settings: {loading: false, expiery: ''}},
            index: selectedIndex,
          },
        })
      }
    }
  },

  getGummiband: (projectId, selectedIndex) => async (dispatch, getState) => {
    const userData = getState()?.auth?.userData

    dispatch({
      type: TEMPORARY_OFFSET_RESHAPE,
      payload: {
        data: {settings: {loading: true, expiery: ''}},
        index: selectedIndex,
      },
    })
    const response = await getGummiband(projectId, userData)

    if (!response.error) {
      if (response.length > 0) {
        const now = new Date()
        dispatch({
          type: TEMPORARY_OFFSET_RESHAPE,
          payload: {
            data: {
              data: response[0].heatingGroups,
              settings: {loading: false, expiery: now.getTime() + 3600000},
            },
            index: selectedIndex,
          },
        })
      } else {
        const now = new Date()
        dispatch({
          type: TEMPORARY_OFFSET_RESHAPE,
          payload: {
            data: {
              settings: {loading: false, expiery: now.getTime() + 3600000},
            },
            index: selectedIndex,
          },
        })
      }
    } else {
      dispatch({
        type: TEMPORARY_OFFSET_RESHAPE,
        payload: {
          data: {settings: {loading: false, expiery: ''}},
          index: selectedIndex,
        },
      })
    }
  },

  getBuildingDetails: () => async (dispatch, getState) => {
    const selectedIndex = getState()?.global?.selectedEntityIndex
    const buildings = getState()?.global?.allEntities

    if (selectedIndex !== '') {
      // RESPONSIBLE TO GET 7D Measures for building
      dispatch(globalActions.getBuilding7dMeasures())

      // RESPONSIBLE TO GET apartments and 7D Measures for each apartment
      dispatch(
        globalActions.getBuildingAppartments({
          buildingIds: buildings[selectedIndex]?.buildingIdentifier,
        }),
      )

      // RESPONSIBLE TO GET LOGS OF BUILDING
      dispatch(
        globalActions.getBuildingLogs({
          projectId: buildings[selectedIndex]?.projectIdentifier,
        }),
      )

      // RESPONSIBLE TO GET HEATING GROUPS
      dispatch(globalActions.getHeatingGroups())

      await dispatch(
        globalActions.getAccessRights(
          buildings[selectedIndex]?.projectIdentifier,
        ),
      )

      dispatch(
        globalActions.getGummiband(
          buildings[selectedIndex]?.projectIdentifier,
          selectedIndex,
        ),
      )
    }
  },

  getAccessRights: (projectId) => async (dispatch, getState) => {
    const userData = getState()?.auth?.userData
    const selectedIndex = getState()?.global?.selectedEntityIndex
    const response = await getAccessRights(projectId, userData)

    if (!response.error) {
      dispatch({
        type: ACCESS_RIGHTS_SET,
        payload: {
          data: response?.length > 0 ? response[0]?.accessRights : [] || [],
          index: selectedIndex,
        },
      })
    } else {
      dispatch({
        type: ACCESS_RIGHTS_SET,
        payload: {
          data: [],
          index: selectedIndex,
        },
      })
    }
  },

  getApartmentMeasures: (apartmentIndex) => async (dispatch, getState) => {
    const userData = getState()?.auth?.userData
    const apartmentMeasures = getState()?.global?.apartmentsMeasures

    let index = null
    for (let i = 0; i < apartmentMeasures?.length; i++) {
      if (apartmentMeasures[i].apartmentIdentifier === apartmentIndex) {
        index = i
      }
    }

    const now = new Date()
    if (
      index !== null &&
      (apartmentMeasures[index]?.settings?.expiery === '' ||
        now?.getTime() >= apartmentMeasures[index]?.settings?.expiery) &&
      apartmentMeasures[index]?.settings?.loading === false
    ) {
      dispatch({
        type: APARTMENT_MEASURES_SET,
        payload: {
          identifier: apartmentIndex,
          data: {
            settings: {loading: true, expiery: ''},
          },
        },
      })
      const response = await getApartmentMeasure(apartmentIndex, userData)
      if (!response.error) {
        const now = new Date()
        dispatch({
          type: APARTMENT_MEASURES_SET,
          payload: {
            identifier: apartmentIndex,
            data: {
              data: response?.length > 0 ? response[0] : {},
              settings: {loading: false, expiery: now.getTime() + 3600000},
            },
          },
        })
      } else {
        dispatch({
          type: APARTMENT_MEASURES_SET,
          payload: {
            identifier: apartmentIndex,
            data: {
              settings: {loading: false, expiery: ''},
            },
          },
        })
      }
    }
  },

  resetAvgMeasuresLoading: () => async (dispatch) => {
    dispatch({
      type: AVG_MEASURES_RESETLOADING,
    })
  },

  apartmentSetFromSearch: (data) => async (dispatch) => {
    dispatch({
      type: APARTMENT_FROM_SEARCH,
      payload: data,
    })
  },

  setSubIdentifier: (identifier) => async (dispatch) => {
    dispatch({
      type: SUB_IDENTIFIER_SET,
      payload: identifier,
    })
  },

  resetSubIdentifier: () => async (dispatch) => {
    dispatch({
      type: SUB_IDENTIFIER_RESET,
    })
  },

  resetApartmentMeasures: () => async (dispatch) => {
    dispatch({
      type: APARTMENT_MEASURES_RESET,
    })
  },
  setEntity: (data) => async (dispatch) => {
    try {
      dispatch({
        type: ENTITY_SET,
        payload: {
          entity: data,
        },
      })
    } catch (error) {
      dispatch({
        type: ENTITY_RESET,
      })
    }
  },

  resetEntity: () => async (dispatch) => {
    dispatch({
      type: ENTITY_RESET,
    })
  },

  setSubEntity: (data) => async (dispatch) => {
    try {
      dispatch({
        type: SUB_ENTITY_SET,
        payload: {
          subEntity: data,
        },
      })
    } catch (error) {
      dispatch({
        type: SUB_ENTITY_RESET,
      })
    }
  },

  resetSubEntity: () => async (dispatch) => {
    dispatch({
      type: SUB_ENTITY_RESET,
    })
  },

  setMenuStateBldg: () => async (dispatch) => {
    try {
      dispatch({
        type: MENU_STATE_SET_BLDG,
      })
    } catch (error) {
      dispatch({
        type: MENU_STATE_RESET,
      })
    }
  },

  setMenuStateProj: () => async (dispatch) => {
    try {
      dispatch({
        type: MENU_STATE_SET_PROJ,
      })
    } catch (error) {
      dispatch({
        type: MENU_STATE_RESET,
      })
    }
  },

  resetMenuState: () => async (dispatch) => {
    dispatch({
      type: MENU_STATE_RESET,
    })
  },

  setLoading: (data) => async (dispatch) => {
    try {
      dispatch({
        type: LOADING_SET,
        payload: {
          loadingData: data,
        },
      })
    } catch (error) {
      dispatch({
        type: LOADING_RESET,
      })
    }
  },

  resetLoading: () => async (dispatch) => {
    dispatch({
      type: LOADING_RESET,
    })
  },

  setIndex: (data) => async (dispatch) => {
    try {
      dispatch({
        type: INDEX_SET,
        payload: {
          selectedEntityIndex: data,
        },
      })
    } catch (error) {
      dispatch({
        type: INDEX_RESET,
      })
    }
  },

  setSubIndex: (data) => async (dispatch) => {
    dispatch({
      type: SUBINDEX_SET,
      payload: {
        subEntityIndex: data,
      },
    })
  },

  resetSubIndex: () => async (dispatch) => {
    dispatch({
      type: SUBINDEX_RESET,
    })
  },

  resetIndex: () => async (dispatch) => {
    dispatch({
      type: INDEX_RESET,
    })
  },

  setPrevPage: (data) => async (dispatch) => {
    try {
      dispatch({
        type: PREV_PAGE_SET,
        payload: {
          prevPage: data,
        },
      })
    } catch (error) {
      dispatch({
        type: PREV_PAGE_RESET,
      })
    }
  },

  resetPrevPage: () => async (dispatch) => {
    dispatch({
      type: PREV_PAGE_RESET,
    })
  },

  refreshBuildingList: (data, history) => async (dispatch) => {
    try {
      const hasConnection = await internetAccess()
      if (hasConnection.status === 200) {
        const loginResponse = await authenticate(data)
        if (loginResponse.error) {
          history.push('/login')
        }
      }

      dispatch({
        type: LOADING_SET,
        payload: {
          loadingData: 2,
        },
      })

      const response = await getBuildingsList(data)

      if (response.error) {
        dispatch({
          type: LOADING_RESET,
        })
        history.push('/login')
      } else {
        await dispatch({
          type: RELOAD_DATA,
          payload: response,
        })
        // await dispatch({
        //   type: MAIN_ENTITIES_SET,
        //   payload: {
        //     allEntities: response.mainData,
        //     emrIdentifiers: response.emrIdentifiers,
        //   },
        // })

        // await dispatch({
        //   type: MAIN_MEASUREMENTS_SET,
        //   payload: response.buildingsDailyMeasures,
        // })
        dispatch({
          type: LOADING_SET,
          payload: {
            loadingData: 3,
          },
        })

        // fetch emr details
        dispatch(globalActions.getEmrDetails())
      }
    } catch (error) {
      dispatch({
        type: LOADING_RESET,
      })
      history.push('/login')

      // dispatch({
      //   type: LOGIN_ERROR,
      //   payload: {
      //     message: '100',
      //   },
      // })
    }
  },
}
