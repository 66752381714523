import React from 'react'
import {Link} from 'react-router-dom'

// ASSET
import logo from '../../assets/icons/ct-logo.svg'

const MainIcon = () => {
  return (
    <Link className="grid place-items-center" to="/home">
      <img style={{height: '24', width: '24'}} src={logo} alt="ECCO2 logo" />
    </Link>
  )
}

export default MainIcon
