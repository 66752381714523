// Chunking array
export const splitArrayIntoChunksOfLen = (arr, len, options) => {
  var chunks = [],
    i = 0,
    n = arr.length
  while (i < n) {
    chunks.push({
      data: arr.slice(i, (i += len)),
      settings: options,
    })
  }
  return chunks
}
