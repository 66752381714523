import {
  BUILDING_SELECT,
  BUILDING_RESET,
  ALL_BUILDINGS_SELECT,
  ALL_BUILDINGS_RESET,
  LOADING_SELECT,
  LOADING_RESET,
  APT_SELECT,
  APT_RESET,
  INDEX_SELECT,
  INDEX_RESET,
  RELHIST_ERROR_SET,
  RELHIST_ERROR_RESET,
  AVGHIST_ERROR_SET,
  AVGHIST_ERROR_RESET,
  APTOFF_ERROR_SET,
  APTOFF_ERROR_RESET,
  HGOFF_ERROR_SET,
  HGOFF_ERROR_RESET,
  HISTORY_ERROR_SET,
  HISTORY_ERROR_RESET,
  VALUES_ERROR_SET,
  VALUES_ERROR_RESET,
  LOG_ERROR_SET,
  LOG_ERROR_RESET,
} from '../actions/building'

const initialState = {
  selectedBuilding: {},
  selectedApt: {},
  allBuildings: {},
  selectedIndex: {},
  loadingData: null,
  alarmError: null,
  avgHistError: null,
  relHistError: null,
  historyError: null,
  valuesError: null,
  logError: null,
}

function building(state = initialState, action) {
  switch (action.type) {
    case BUILDING_SELECT:
      return Object.assign({}, state, {
        selectedBuilding: action.payload.selectedBuilding,
      })
    case BUILDING_RESET:
      return Object.assign({}, state, {
        selectedBuilding: {},
      })
    case APT_SELECT:
      return Object.assign({}, state, {
        selectedApt: action.payload.selectedApt,
      })
    case APT_RESET:
      return Object.assign({}, state, {
        selectedApt: {},
      })
    case INDEX_SELECT:
      return Object.assign({}, state, {
        selectedIndex: action.payload.selectedIndex,
      })
    case INDEX_RESET:
      return Object.assign({}, state, {
        selectedIndex: {},
      })
    case ALL_BUILDINGS_SELECT:
      return Object.assign({}, state, {
        allBuildings: action.payload.allBuildings,
      })
    case ALL_BUILDINGS_RESET:
      return Object.assign({}, state, {
        allBuildings: {},
      })
    case LOADING_SELECT:
      return Object.assign({}, state, {
        loadingData: action.payload.loadingData,
      })
    case LOADING_RESET:
      return Object.assign({}, state, {
        loadingData: null,
      })
    case AVGHIST_ERROR_SET:
      return Object.assign({}, state, {
        avgHistError: action.payload.avgHistError,
      })
    case AVGHIST_ERROR_RESET:
      return Object.assign({}, state, {
        avgHistError: null,
      })
    case RELHIST_ERROR_SET:
      return Object.assign({}, state, {
        relHistError: action.payload.relHistError,
      })
    case RELHIST_ERROR_RESET:
      return Object.assign({}, state, {
        relHistError: null,
      })
    case APTOFF_ERROR_SET:
      return Object.assign({}, state, {
        aptOffError: action.payload.aptOffError,
      })
    case APTOFF_ERROR_RESET:
      return Object.assign({}, state, {
        aptOffError: null,
      })
    case HGOFF_ERROR_SET:
      return Object.assign({}, state, {
        hgOffError: action.payload.hgOffError,
      })
    case HGOFF_ERROR_RESET:
      return Object.assign({}, state, {
        hgOffError: null,
      })
    case HISTORY_ERROR_SET:
      return Object.assign({}, state, {
        historyError: action.payload.historyError,
      })
    case HISTORY_ERROR_RESET:
      return Object.assign({}, state, {
        historyError: null,
      })
    case VALUES_ERROR_SET:
      return Object.assign({}, state, {
        valuesError: action.payload.valuesError,
      })
    case VALUES_ERROR_RESET:
      return Object.assign({}, state, {
        valuesError: null,
      })
    case LOG_ERROR_SET:
      return Object.assign({}, state, {
        logError: action.payload.logError,
      })
    case LOG_ERROR_RESET:
      return Object.assign({}, state, {
        logError: null,
      })
    default:
      return state
  }
}

export default building
