import React, {useState, useContext} from 'react'

import {getSvg} from '../../utils/getSvg'
import {languageContext} from '../../languageContext'
import textsData from '../../staticTexts'

const MenuButton = ({change, sidebarStatus}) => {
  const {value} = useContext(languageContext)
  const [texts] = useState(textsData[14])

  return (
    <div>
      <div className="m-0 grid place-items-center">
        <button
          onClick={() => {
            change(!sidebarStatus)
          }}
          type="button"
          className="p-2 m-0 rounded-sm hover:bg-ecco2blue-50"
          aria-controls="mobile-menu"
          aria-expanded="false"
        >
          {getSvg('menuIcon', '#fff', 21, 17)}
        </button>
      </div>
      <div
        className="m-0 font-semibold grid place-items-center capitalize text-white"
        style={{fontSize: '9px', lineHeight: '11px'}}
      >
        {texts[value].menuBtnText}
      </div>
    </div>
  )
}

export default MenuButton
