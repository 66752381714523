import React from 'react'
import BeatLoader from 'react-spinners/BeatLoader'

const TopLoader = ({text, loading, size}) => {
  return (
    <div className="h-auto w-full grid place-items-center bg-white pt-1 pb-2">
      <BeatLoader color="#026AAB" loading={loading} size={size} />
      <p className="text-lg text-ecco2blue-100 text-center">{text}</p>
    </div>
  )
}

export default TopLoader
