import React from 'react'
import {Route, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'

//* Obliging the user to be logged in in order to navigate to other routes
const PrivateRoute = ({component: Component, token, ...rest}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        token ? (
          <Component {...props} />
        ) : (
          <Redirect to={{pathname: '/login', state: {from: props.location}}} />
        )
      }
    />
  )
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
})

export default connect(mapStateToProps)(PrivateRoute)
