export const BUILDING_SELECT = 'BUILDING_SELECT'
export const BUILDING_RESET = 'BUILDING_RESET'
export const APT_SELECT = 'APT_SELECT'
export const APT_RESET = 'APT_RESET'
export const ALL_BUILDINGS_SELECT = 'ALL_BUILDINGS_SELECT'
export const ALL_BUILDINGS_RESET = 'ALL_BUILDINGS_RESET'
export const LOADING_SELECT = 'LOADING_SELECT'
export const LOADING_RESET = 'LOADING_RESET'
export const INDEX_SELECT = 'INDEX_SELECT'
export const INDEX_RESET = 'INDEX_RESET'
export const RELHIST_ERROR_SET = 'RELHIST_ERROR_SET'
export const RELHIST_ERROR_RESET = 'RELHIST_ERROR_RESET'
export const APTOFF_ERROR_SET = 'APTOFF_ERROR_SET'
export const APTOFF_ERROR_RESET = 'APTOFF_ERROR_RESET'
export const HGOFF_ERROR_SET = 'HGOFF_ERROR_SET'
export const HGOFF_ERROR_RESET = 'HGOFF_ERROR_RESET'
export const AVGHIST_ERROR_SET = 'AVGHIST_ERROR_SET'
export const AVGHIST_ERROR_RESET = 'AVGHIST_ERROR_RESET'
export const HISTORY_ERROR_SET = 'HISTORY_ERROR_SET'
export const HISTORY_ERROR_RESET = 'HISTORY_ERROR_RESET'
export const VALUES_ERROR_SET = 'VALUES_ERROR_SET'
export const VALUES_ERROR_RESET = 'VALUES_ERROR_RESET'
export const LOG_ERROR_SET = 'LOG_ERROR_SET'
export const LOG_ERROR_RESET = 'LOG_ERROR_RESET'

export const bldgActions = {
  setBuilding: (data) => async (dispatch) => {
    try {
      dispatch({
        type: BUILDING_SELECT,
        payload: {
          selectedBuilding: data,
        },
      })
    } catch (error) {
      dispatch({
        type: BUILDING_RESET,
      })
    }
  },

  setAllBuildings: (data) => async (dispatch) => {
    try {
      dispatch({
        type: ALL_BUILDINGS_SELECT,
        payload: {
          allBuildings: data,
        },
      })
    } catch (error) {
      dispatch({
        type: ALL_BUILDINGS_RESET,
      })
    }
  },

  setLoading: (data) => async (dispatch) => {
    try {
      dispatch({
        type: LOADING_SELECT,
        payload: {
          loadingData: data,
        },
      })
    } catch (error) {
      dispatch({
        type: LOADING_RESET,
      })
    }
  },

  setIndex: (data) => async (dispatch) => {
    try {
      dispatch({
        type: INDEX_SELECT,
        payload: {
          selectedIndex: data,
        },
      })
    } catch (error) {
      dispatch({
        type: INDEX_RESET,
      })
    }
  },

  setApt: (data) => async (dispatch) => {
    try {
      dispatch({
        type: APT_SELECT,
        payload: {
          selectedApt: data,
        },
      })
    } catch (error) {
      dispatch({
        type: APT_RESET,
      })
    }
  },

  emptyBuilding: () => async (dispatch) => {
    dispatch({
      type: BUILDING_RESET,
    })
  },

  emptyAllBuildings: () => async (dispatch) => {
    dispatch({
      type: ALL_BUILDINGS_RESET,
    })
  },

  resetLoading: () => async (dispatch) => {
    dispatch({
      type: LOADING_RESET,
    })
  },

  emptyApt: () => async (dispatch) => {
    dispatch({
      type: APT_RESET,
    })
  },
}
