import fetch from 'isomorphic-fetch'

let {BASE_URL} = require('./config')

// Api used to add emr
export const postEmr = async (user, emrEntry, reEntry = false) => {
  try {
    let emrInsert = await fetch(`${BASE_URL}broker/publish`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Basic ${window.btoa(
          `${user.username}:${user.password}`,
        )}`,
      },
      body: JSON.stringify({request: [emrEntry]}),
    })

    if (!emrInsert.ok || emrInsert.url !== `${BASE_URL}broker/publish`) {
      let error = {
        message: emrInsert.status,
      }

      throw error
    }

    return emrInsert
  } catch (err) {
    if (!reEntry) {
      const now = new Date()
      localStorage.setItem(`emr.${now.getTime()}`, JSON.stringify(emrEntry))
    }
    return 'error'
  }
}
