import React from 'react'
import {getSvg} from '../../utils/getSvg'
const Modal = ({children, title, click}) => {
  return (
    <section style={{zIndex: 9}} className="inset-0">
      <div
        className="fixed w-full h-full inset-0 bg-gray-500 bg-opacity-75 z-40 transition-opacity"
        aria-hidden="true"
        onClick={() => {
          click()
        }}
      />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div className=" fixed top-32  w-full md:w-1/2 p-3 z-50 flex justify-center ">
          <div className="bg-white w-full rounded-lg overflow-hidden">
            <div className="flex justify-between p-4 uppercase bg-ecco2blue-100">
              <p className="text-white font-bold">{title}</p>
              <button
                onClick={() => {
                  click()
                }}
                className="rounded-md text-white focus:outline-none"
              >
                {getSvg('closeXIcon', '#fff', 24, 24)}
              </button>
            </div>
            {children}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Modal
