export const PROJECT_SET = 'PROJECT_SET'
export const PROJECT_RESET = 'PROJECT_RESET'
export const MAIN_ENTITIES_SET = 'MAIN_ENTITIES_SET'
export const MAIN_ENTITIES_RESET = 'MAIN_ENTITIES_RESET'
// export const LOADING_SET = "LOADING_SET";
// export const LOADING_RESET = "LOADING_RESET";
export const INDEX_SET = 'INDEX_SET'
export const INDEX_RESET = 'INDEX_RESET'
export const DATA_ERROR_SET = 'DATA_ERROR_SET'
export const DATA_ERROR_RESET = 'DATA_ERROR_RESET'

export const actions = {
  setAllEntities: (data) => async (dispatch) => {
    try {
      dispatch({
        type: MAIN_ENTITIES_SET,
        payload: {
          allEntities: data,
        },
      })
    } catch (error) {
      dispatch({
        type: MAIN_ENTITIES_RESET,
      })
    }
  },

  resetAllEntities: () => async (dispatch) => {
    dispatch({
      type: MAIN_ENTITIES_RESET,
    })
  },

  setProject: (data) => async (dispatch) => {
    try {
      dispatch({
        type: PROJECT_SET,
        payload: {
          selectedProject: data,
        },
      })
    } catch (error) {
      dispatch({
        type: PROJECT_RESET,
      })
    }
  },

  resetProject: () => async (dispatch) => {
    dispatch({
      type: PROJECT_RESET,
    })
  },

  setIndex: (data) => async (dispatch) => {
    try {
      dispatch({
        type: INDEX_SET,
        payload: {
          selectedIndex: data,
        },
      })
    } catch (error) {
      dispatch({
        type: INDEX_RESET,
      })
    }
  },

  // setLoading: (data) => async (dispatch) => {
  //   try {
  //     dispatch({
  //       type: LOADING_SET,
  //       payload: {
  //         loadingData: data,
  //       },
  //     });
  //   } catch (error) {
  //     dispatch({
  //       type: LOADING_RESET,
  //     });
  //   }
  // },

  // resetLoading: () => async (dispatch) => {
  //   dispatch({
  //     type: LOADING_RESET,
  //   });
  // },
}
