import React, {useState, useContext} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'

import {actions} from '../../redux/actions/auth'
import {languageContext} from '../../languageContext'
import textsData from '../../staticTexts'
import {getSvg} from '../../utils/getSvg'
import {version} from '../../utils/version'

const SideMenu = ({
  sidebarStatus,
  active,
  change,
  texts,
  navArray,
  langArray,
  logout,

  selectedEntityIndex,
  allEntities,
}) => {
  //* Getting the selected language by the user
  const {value, setValue} = useContext(languageContext)
  const [staticTexts] = useState(textsData[14])

  const returnAccessRight = (accessRights, right) => {
    let final = false
    accessRights?.map((zone) => {
      if (zone === right) {
        final = true
      }
    })
    return final
  }

  return (
    <div className="fixed inset-0 overflow-hidden">
      <div
        className="w-full h-full z-20"
        onClick={() => {
          change(!sidebarStatus)
        }}
      ></div>
      <div className="absolute inset-y-0 right-0  max-w-full flex z-50">
        <div className="h-full w-56 flex flex-col py-6 bg-ecco2blue-100 shadow-xl ">
          <button
            onClick={() => {
              change(!sidebarStatus)
            }}
            className="rounded-sm ml-3 h-6 w-6 text-white fill-current focus:outline-none"
          >
            {getSvg('closeXIcon', '#fff', 24, 24)}
          </button>
          <div className="mt-6 flex-1 px-6">
            {active === 'login' ? null : active === 'home' ||
              active === 'favorites' ? (
              <>
                <div
                  className={`text-white mt-3 mb-3 pl-2 py-2 ${
                    active === 'home'
                      ? 'rounded-md  bg-ecco2blue-200 transform scale-95'
                      : ''
                  }`}
                >
                  <Link
                    onClick={() => {
                      change(false)
                    }}
                    className="flex"
                    to="/home"
                  >
                    {getSvg('buildingsIcon', '#fff', 25, 25)}
                    <div className="ml-3 ">
                      {staticTexts[value].buildingsBtnText}
                    </div>
                  </Link>
                </div>
                <div
                  className={`text-white mt-3 mb-3 pl-2 py-2 ${
                    active === 'favorites'
                      ? 'rounded-md  bg-ecco2blue-200 transform scale-95'
                      : ''
                  }`}
                >
                  <Link
                    onClick={() => {
                      change(false)
                    }}
                    className="flex"
                    to="/favorites"
                  >
                    {getSvg('favoritesIcon', '#fff', 25, 25)}
                    <div className="ml-3 ">
                      {staticTexts[value].favoritesBtnText}
                    </div>
                  </Link>
                </div>
              </>
            ) : (
              <div>
                <div>
                  {navArray[1].map((nav, i) => {
                    if (nav.title === 'apartments') {
                      return allEntities[selectedEntityIndex]?.sensorCount >
                        0 ? (
                        <div
                          key={i}
                          className={`text-white mt-3 mb-3 pl-2 py-2 ${
                            active === nav.title
                              ? 'rounded-md  bg-ecco2blue-200 transform scale-95'
                              : ''
                          }`}
                        >
                          <Link
                            onClick={() => {
                              change(false)
                            }}
                            className="flex"
                            to={`/${nav.title}`}
                          >
                            {getSvg(
                              nav.svgSettings.name,
                              nav.svgSettings.color,
                              nav.svgSettings.width,
                              nav.svgSettings.height,
                            )}
                            <div className="ml-3 ">{texts[`${nav.index}`]}</div>
                          </Link>
                        </div>
                      ) : null
                    } else if (nav.title === 'emr') {
                      return selectedEntityIndex != '' &&
                        allEntities[selectedEntityIndex]?.EMRIdentifiers
                          ?.length > 0 ? (
                        <div
                          key={i}
                          className={`text-white mt-3 mb-3 pl-2 py-2 ${
                            active === nav.title
                              ? 'rounded-md  bg-ecco2blue-200 transform scale-95'
                              : ''
                          }`}
                        >
                          <Link
                            onClick={() => {
                              change(false)
                            }}
                            className="flex"
                            to={`/${nav.title}`}
                          >
                            {getSvg(
                              nav.svgSettings.name,
                              nav.svgSettings.color,
                              nav.svgSettings.width,
                              nav.svgSettings.height,
                            )}
                            <div className="ml-3 ">{texts[`${nav.index}`]}</div>
                          </Link>
                        </div>
                      ) : null
                    } else if (nav.title === 'temporary-offset') {
                      return selectedEntityIndex != '' &&
                        returnAccessRight(
                          allEntities[selectedEntityIndex]?.accessRights,
                          'CanChangeOffset',
                        ) === true ? (
                        <div
                          key={i}
                          className={`text-white mt-3 mb-3 pl-2 py-2 ${
                            active === nav.title
                              ? 'rounded-md  bg-ecco2blue-200 transform scale-95'
                              : ''
                          }`}
                        >
                          <Link
                            onClick={() => {
                              change(false)
                            }}
                            className="flex"
                            to={`/${nav.title}`}
                          >
                            {getSvg(
                              nav.svgSettings.name,
                              nav.svgSettings.color,
                              nav.svgSettings.width,
                              nav.svgSettings.height,
                            )}
                            <div className="ml-3 ">{texts[`${nav.index}`]}</div>
                          </Link>
                        </div>
                      ) : null
                    } else {
                      return (
                        <div key={i}>
                          <div
                            className={`text-white mt-3 mb-3 pl-2 py-2 ${
                              active === nav.title
                                ? 'rounded-md  bg-ecco2blue-200 transform scale-95'
                                : ''
                            }`}
                          >
                            <Link
                              onClick={() => {
                                change(false)
                              }}
                              className="flex"
                              to={`/${nav.title}`}
                            >
                              {getSvg(
                                nav.svgSettings.name,
                                nav.svgSettings.color,
                                nav.svgSettings.width,
                                nav.svgSettings.height,
                              )}
                              <div className="ml-3 ">
                                {texts[`${nav.index}`]}
                              </div>
                            </Link>
                          </div>
                          {nav.title === 'favorites' && (
                            <hr className="mt-4 mb-2" />
                          )}
                        </div>
                      )
                    }
                  })}
                </div>
              </div>
            )}

            <hr className="mt-2 mb-2" />
            <div className="text-white mb-1">{texts.languageText}</div>

            <div className="pl-1 pr-1 flex justify-between ">
              {langArray.map((language, i) => (
                <div
                  onClick={() => {
                    setValue(i + 1)
                    change(false)
                  }}
                  className={`w-min p-2 text-white cursor-pointer  ${
                    value === i + 1
                      ? 'rounded-md bg-ecco2blue-200 transform scale-95'
                      : ''
                  }`}
                  key={i}
                >
                  {texts[`${language}`]}
                </div>
              ))}
            </div>
            <hr className="mt-4 mb-2" />

            {active === 'login' ? null : (
              <div
                onClick={() => {
                  logout()
                  change(false)
                }}
                to="/login"
                className="text-white flex cursor-pointer pt-8"
              >
                {getSvg('logoutArrowIcon', '#fff', 24, 24)}
                <span className="ml-2">{texts.logoutText}</span>
              </div>
            )}
          </div>
          <span className="text-white mx-auto mb-10">{version}</span>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  selectedEntityIndex: state.global.selectedEntityIndex,
  allEntities: state.global.allEntities,
})

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(actions.logOut()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(SideMenu))
