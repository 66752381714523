import {combineReducers} from 'redux'
import auth from './auth'
import building from './building'
import project from './project'
import global from './global'

const reducers = combineReducers({
  auth,
  building,
  project,
  global,
})

export default reducers
