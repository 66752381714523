/* eslint-disable no-underscore-dangle */
import {createStore, applyMiddleware} from 'redux'
import {persistStore, persistReducer} from 'redux-persist'

import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import {composeWithDevTools} from 'redux-devtools-extension'
import reducers from '../reducers/reducers'
import thunk from 'redux-thunk'
import localforage from 'localforage'

//* Configure persister, storing a token on the user's device when he logs in
const persistConfig = {
  key: 'caretaker-user',
  storage: localforage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['modalReducer'],
}

export const configureStore = () => {
  const pReducer = persistReducer(persistConfig, reducers)
  return createStore(pReducer, applyMiddleware(thunk), composeWithDevTools())
}

export const configurePersistor = (store) => persistStore(store)
